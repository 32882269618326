.page-content {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 15px;
  bottom: 15px;
}

iframe {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
}
